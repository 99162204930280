<template>
  <div class="shop-goods-list-page">
    <en-table-layout :tableData="pageData.data" @selection-change="handleSelectionChange"
      @sort-change="handleSortChange">
      <template slot="toolbar">
        <el-form-item label="状态" class="col-auto" size="small">
          <el-select v-model="params.status" placeholder="请选择" clearable style="width:80px;" size="small">
            <el-option label="全部" value></el-option>
            <el-option label="禁用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="force_exchange_goods_open" label="是否开启智能换货" class="col-auto" size="small">
          <el-select v-model="params.card_exchange" placeholder="请选择" clearable style="width:90px;" size="small">
            <el-option label="全部" value></el-option>
            <el-option label="未参与" :value="0"></el-option>
            <el-option label="已参与" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" class="col-auto" size="small">
          <el-date-picker style="width: 220px" v-model="createTimeFilter" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="timestamp" :default-time="['00:00:00', '23:59:59']"
            @change="createTimeFilterChange"></el-date-picker>
        </el-form-item>
        <el-form-item label="客户名称" class="col-auto" size="small">
          <el-input style="width: 150px;" size="small" placeholder="请输入客户名称" v-model.trim="params.client_name"
            clearable></el-input>
        </el-form-item>
        <el-form-item class="col-auto" size="small">
          <el-input style="width: 300px;" size="medium" placeholder="请输入关键词" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" placeholder="请选择" style="width: 120px;">
              <el-option label="卡券名称" value="card_name"></el-option>
              <el-option label="卡券编号" value="id"></el-option>
              <el-option label="业务员名称" value="salesman_name"></el-option>
              <el-option label="卡号" value="card_code"></el-option>
              <el-option label="备注信息" value="remark"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="col-auto" size="small">
          <el-button @click="search" size="small" type="primary">搜索</el-button>
        </el-form-item>

        <div class="col"></div>
        <div class="col-auto"></div>
      </template>

      <template slot="toolbar_btn">
        <div style="margin-bottom:8px;">
          <el-checkbox v-model="isAllChecked" :true-label="1" :false-label="0"
            style="margin-right:10px;">全选</el-checkbox>
          <el-button size="small" @click="exportQrCode" type="primary">导出勾选选项二维码</el-button>
          <el-button @click="exportCheck" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="handleAddCard" type="primary" size="small">添加</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" />
        <el-table-column :key="'code'" label="卡券编号" width="100" fixed="left">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="卡券名称" :show-overflow-tooltip="true" width="150" fixed="left">

          <template slot-scope="scope">{{ scope.row.card_name }}</template>
        </el-table-column>
        <el-table-column label="前端展示名称" :show-overflow-tooltip="true" width="150" fixed="left">

          <template slot-scope="scope">
            <div v-if="!scope.row.showWebNameInput" @click="_ => $set(scope.row, 'showWebNameInput', true)"
              style="width:100%;min-height:20px">
              {{ scope.row.web_card_name }}</div>
            <el-input v-else v-model.trim="scope.row.web_card_name" :maxlength="32" :minlength="1" v-focus
              @blur="changeWebName(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="兑换二维码" width="100" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.qr_img_url" alt style="width: 300px" />
              <img :src="row.qr_img_url" class="goods-cover" alt slot="reference" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="卡券价位" width="80" :show-overflow-tooltip="true">

          <template slot-scope="scope">{{ scope.row.card_value }}</template>
        </el-table-column>
        <el-table-column label="卡券分销价" width="100" :show-overflow-tooltip="true">

          <template slot-scope="scope">{{ scope.row.distribution_price }}</template>
        </el-table-column>
        <el-table-column v-if="parentLogin" label="折扣率" width="100">
          <template slot-scope="scope">{{ scope.row.discount_rate }}%</template>
        </el-table-column>
        <el-table-column label="客户名称" width="80" :show-overflow-tooltip="true">

          <template slot-scope="scope">{{ scope.row.client_name }}</template>
        </el-table-column>
        <el-table-column label="业务员名称" width="100" :show-overflow-tooltip="true">

          <template slot-scope="scope">{{ scope.row.salesman_name }}</template>
        </el-table-column>
        <el-table-column label="预览二维码" width="100" class-name="goods-cover-wrapper">

          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.pvw_qr_img_url" alt style="width: 300px" />
              <img :src="row.pvw_qr_img_url" class="goods-cover" alt slot="reference" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="有效期" width="80" prop="price">

          <template slot-scope="scope">{{ scope.row.validity_year }}月</template>
        </el-table-column>
        <el-table-column label="总数" width="80" prop="keynum" sortable="custom">

          <template slot-scope="scope">{{ scope.row.key_num }}</template>
        </el-table-column>
        <el-table-column label="已激活数" width="110" prop="activenum" sortable="custom">

          <template slot-scope="scope">
            <span class="num" @click="handleClickNum(scope.row, 'activeNum')">
              {{
      scope.row.active_num
    }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="已兑换数" width="110" prop="exchangenum" sortable="custom">

          <template slot-scope="scope">
            <span class="num" @click="handleClickNum(scope.row, 'usedNum')">
              {{
      scope.row.use_num
    }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="已过期数" width="110" prop="expirenum" sortable="custom">

          <template slot-scope="scope">
            <span class="num" @click="handleClickNum(scope.row, 'expNum')">
              {{
      scope.row.expire_num
    }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="145">

          <template slot-scope="scope">
            <span>{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="60">

          <template slot-scope="scope">
            <span>{{ scope.row.status | cardStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="force_exchange_goods_open" label="是否开启智能换货" width="140">

          <template slot-scope="scope">
            <el-switch @change="cardExchange($event, scope.row)" v-model="scope.row.card_exchange"
              :disabled="false"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="备注信息" width="120" show-overflow-tooltip>

          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">

          <template slot-scope="scope">
            <el-button size="mini" type="danger" v-if="scope.row.status === 1"
              @click="handleNoUseCard(scope.row)">禁用</el-button>
            <el-button size="mini" type="primary" v-if="scope.row.status === 0"
              @click="handleUseCard(scope.row)">启用</el-button>
            <el-button size="mini" type="primary" @click="handleEditCard(scope.row)">编辑</el-button>
            <el-button size="mini" type="primary" @click="handleGrant(scope.row)">发放</el-button>
            <el-button size="mini" type="primary" @click="remarkOpen(scope.row)">备注</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入100字以内的备注信息" maxlength="100"
          v-model="remarkForm.remark"></el-input>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_goods from '@/api/goods'
import * as API_Card from '@/api/combo-card-shop/card'
import * as API_Combo from '@/api/combo-card-shop/combo'
import * as API_Order from '@/api/order'
import EnTableLayout from '@/../ui-components/TableLayout/src/main'
import mixin from './components/mixin'
import XDialog from '@/components/x-dialog/x-dialog'
import { $xDialog } from '@/components/x-dialog/dialog.proxy'
import { handleDownload } from '@/utils'

export default {
  name: 'cardList',
  mixins: [mixin],
  components: {
    EnTableLayout,
    XDialog
  },
  data () {
    return {
      importLoading: false,
      force_exchange_goods_open: false,
      remarkForm: {},
      remarkDialog: $xDialog.create({
        title: '备注信息',
        width: '40vw',
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.remarkCardKeyTh
      }),
      /** 列表loading状态 */
      loading: false,
      /** 绑卡记录列表loading状态 */
      comboRecordsLoading: false,
      idsList: [],
      isAllChecked: false,

      showDialog: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        status: '',
        card_type: 5,
        client_name: '',
        card_exchange: '',
        keyWord: ''
      },
      key_word: 'card_name',
      /** 列表分页数据 */
      pageData: {},
      /**是否是企业跳转过来 */
      parentLogin: 0,
      createTimeFilter: []
    };
  },
  filters: {
    /** 礼包状态格式化 */
    cardStatus (status) {
      switch (status) {
        case 0:
          return '禁用'
        case 1:
          return '启用'
      }
    }
  },
  created () {
    API_goods.getLoginType().then(res => {
      this.parentLogin = res.parentLogin
    })
    API_Order.getShopExtAuth().then(res => {
      this.force_exchange_goods_open = res.force_exchange_goods_open === 'OPEN'
      console.log(res)
    })
  },
  activated () {
    this.GET_CardList()
  },
  mounted () {
    this.GET_CardList()
  },
  methods: {
    // 导出选中
    exportCheck () {
      const filterVals = [
        'id',
        'card_name',
        'web_card_name',
        'qr_img_url',
        'card_value',
        'distribution_price',
        'discount_rate',//折扣率
        'client_name',
        'salesman_name',
        'pvw_qr_img_url',
        'validity_year',
        'key_num',
        'active_num',
        'use_num',
        'expire_num',
        'status',
        'remark'
      ]
      const tHeaders = [
        '卡券编号',
        '卡券名称',
        '前端展示名称',
        '兑换二维码',
        '卡券价位',
        '卡券分销价',
        '折扣率',
        '客户名称',
        '业务员名称',
        '预览二维码',
        '有效期',
        '总数',
        '已激活数',
        '已兑换数',
        '已过期数',
        '状态',
        '备注信息'
      ]
      if (this.parentLogin == 0) {//非企业跳站点
        tHeaders = tHeaders.map(item => {
          return item != "折扣率"
        })
      }
      let params = {
        ...this.params
      }
      params[this.key_word] = params.keyWord
      // if (this.idsList.length === 0)
      //   return this.$message.error('请先勾选要导出的卡券')
      // let snArr = []
      this.importLoading = true
      params.page_no = 0
      params.page_size = 0
      // params.ids = this.idsList
      // params.order_status = "ALL"
      API_Card.getCardList(params)
        .then(res => {
          let data = res.data.map(res => {
            res.status = res.status === 1 ? '启用' : '禁用'
            return res
          })
          handleDownload(data, tHeaders, filterVals, '卡券列表')
          this.importLoading = false
        })
        .catch(res => {
          this.importLoading = false
        })
    },
    handleSortChange ({ prop, order }) {
      console.log(prop, order)
      if (order) {
        this.params.sort = `${prop}_${order.includes('asc') ? 'asc' : 'desc'}`
      } else {
        this.params.sort = ''
      }
      this.GET_CardList()
    },
    createTimeFilterChange (date) {
      // debugger;
      if (Array.isArray(date)) {
        this.params.create_time_start = date[0] / 1000
        this.params.create_time_end = date[1] / 1000
      } else {
        this.params.create_time_start = ''
        this.params.create_time_end = ''
      }
    },
    cardExchange (val, row) {
      console.log(val, row)
      API_Combo.comboEditAiExchangeTh(row.id, val ? 1 : 0).then(res => {
        console.log(res)
      })
    },
    remarkCardKeyTh () {
      console.log(this.remarkForm)
      API_Combo.editRemark(this.remarkForm.id, this.remarkForm).then(res => {
        this.GET_CardList()
        console.log(res)
      })
    },
    remarkOpen (row) {
      this.remarkDialog.display()
      this.remarkForm = { id: row.id, remark: row.remark }
      console.log(row)
    },
    handleGrant (row) {
      this.$router.push({
        name: 'cardBatchComboCard',
        params: { card_id: row.id, card_type: row.card_type }
      })
    },
    handleClickNum (row, numType) {
      numType === 'activeNum' &&
        this.$router.push({
          name: 'numActiveListComboCard',
          params: { card_id: row.id }
        })
      numType === 'usedNum' &&
        this.$router.push({
          name: 'numUsedListComboCard',
          params: { card_id: row.id }
        })
      numType === 'expNum' &&
        this.$router.push({
          name: 'numExpListComboCard',
          params: { card_id: row.id }
        })
    },
    /**
     * 添加套餐
     */
    handleAddCard () {
      this.$router.push({
        name: 'cardEditComboCard'
      })
    },
    /**
     * 导出勾选选项二维码
     */
    exportQrCode () {
      if (!this.isAllChecked && !this.idsList.length) {
        this.$message.error('请勾选要导出二维码图片的卡券。')
        return
      }
      let _params = {
        ids: this.idsList,
        is_all: this.isAllChecked ? 1 : 0,
        // sign: 1,// 1:在线发放，2:卡密管理
        active: this.params.status,
        keyWord: '',
        card_name: this.params.card_name,
        card_type: this.params.card_type,
      }

      API_Card.qrExport(_params).then(res => {
        this.$message.success('导出成功。')
        this.save(res)
      })
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size
      this.GET_CardList()
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page
      this.GET_CardList()
    },

    search () {
      this.params.page_no = 1
      this.GET_CardList()
    },

    /** 禁用*/
    handleNoUseCard (row) {
      this.$confirm('确定要禁用这个卡券吗？', '提示', {
        type: 'warning'
      }).then(() => {
        API_Card.changeStatus({ id: row.id, status: 0 }).then(() => {
          this.$message.success('禁用成功')
          this.GET_CardList()
        })
      })
    },

    /** 启用*/
    handleUseCard (row) {
      this.$confirm('确定要启用这个卡券吗？', '提示', {
        type: 'warning'
      }).then(() => {
        API_Card.changeStatus({ id: row.id, status: 1 }).then(() => {
          this.$message.success('启用成功')
          this.GET_CardList()
        })
      })
    },

    GET_CardList () {
      this.loading = true
      let params = { ...this.params }
      params[this.key_word] = params.keyWord
      delete params.keyWord
      API_Card.getCardList(params).then(response => {
        if (
          response.data &&
          response.data.length === 0 &&
          response.page_no > 1
        ) {
          this.params.page_no--
          return this.GET_CardList()
        }

        this.loading = false
        this.pageData = response
        this.pageData.data = this.pageData.data.map(item => {
          item.card_exchange = item.card_exchange === 1
          return item
        })
      })
    },

    /** 编辑礼包*/
    handleEditCard (row) {
      this.$router.push({
        name: 'cardEditComboCard',
        params: {
          id: row.id,
          callback: this.GET_CardList
        }
      })
    },

    handleSelectionChange (val) {
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })

      this.idsList = ids
    },
    /**更改礼包前端名称 */
    async changeWebName (row) {
      row.showWebNameInput = false
      row.web_card_name = row.web_card_name.trim()
      const { id, web_card_name } = row
      if (!web_card_name) {
        this.$message.warning('卡券前端名称不可为空')
        this.GET_CardList()
        return
      }
      try {
        const res = await API_Combo.editCardCombEditName({ id, web_card_name })
        this.$message.success('修改成功')
      } catch (error) {
        this.$message.warning(error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.num {
  cursor: pointer;
  color: #2155d5;
  text-decoration: underline;
}

.num:hover {
  text-decoration: underline;
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}
</style>
